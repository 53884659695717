import { UseFormRegisterReturn } from "react-hook-form"

interface WeeklyInputProps {
  label: string
  activityRegister: UseFormRegisterReturn
  hourRegister: UseFormRegisterReturn
}

export default function WeeklyInput({ label, activityRegister, hourRegister }: WeeklyInputProps) {
  return (
    <div className="weekly-input">
      <label>{label}</label>
      <input type="text" placeholder="Actividad" maxLength={200} {...activityRegister} />
      <input type="time" {...hourRegister} />
    </div>
  )
}
