import { UseFormRegisterReturn } from "react-hook-form"

interface InputProps {
  name: string
  label?: string
  placeholder?: string
  error?: string
  disabled?: boolean
  type?: string
  register?: UseFormRegisterReturn
}

export default function Input({
  name,
  label,
  placeholder,
  error,
  disabled = false,
  type = "text",
  register
}: InputProps) {
  return (
    <div className="fam-groups">
      <label>{label}</label>
      <input type={type} name={name} disabled={disabled} placeholder={placeholder} {...register} />
      {error ? <p className="instructions">{error}</p> : null}
    </div>
  )
}
