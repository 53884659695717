import { AxiosResponse } from "axios"
import { GeopolQuestionary } from "../components/views/private/questionary/Geopol"
import axiosCodeigniter from "../api/axiosCodeigniter"

const sendGeopolQuestionary = async (data: GeopolQuestionary): Promise<AxiosResponse> => {
  const { photos, ...cleanedData } = data
  const token = localStorage.getItem("token")
  const formData = new FormData()

  formData.append("data", JSON.stringify(cleanedData))
  if (photos.front) formData.append("photo_front", photos.front)
  if (photos.side) formData.append("photo_side", photos.side)
  if (photos.back) formData.append("photo_back", photos.back)

  return await axiosCodeigniter.post("/geopol-new-plan-api", formData, {
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}` }
  })
}

export default sendGeopolQuestionary
