import { UseFormRegisterReturn } from "react-hook-form"

interface MealDescriptionProps {
  label: string
  register: UseFormRegisterReturn
}

export default function MealDescription({ label, register }: MealDescriptionProps) {
  return (
    <div className="meal-description">
      <label>{label}</label>
      <textarea {...register} placeholder="Escribe una descripción..."></textarea>
    </div>
  )
}
