import { UseFormRegisterReturn } from "react-hook-form"

interface FoodFrequencyProps {
  label: string
  register: UseFormRegisterReturn
}

export default function FoodFrequency({ label, register }: FoodFrequencyProps) {
  return (
    <div className="food-frequency">
      <label>{label}</label>
      <select defaultValue="nunca" {...register}>
        <option value="nunca">Nunca</option>
        <option value="diario">Diario</option>
        <option value="semanal">Semanal</option>
        <option value="ocasional">Ocasional</option>
        <option value="mensual">Mensual</option>
      </select>
    </div>
  )
}
