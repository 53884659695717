import previousArrow from "../../../../../assets/img/arrow-left-form.svg"
import nextArrow from "../../../../../assets/img/arrow-right-form.svg"

interface StepControllerProps {
  onPreviousClick?: () => void
  onNextClick?: () => void
  showPrevious?: boolean
  showNext?: boolean
}

export default function StepController({
  onPreviousClick,
  onNextClick,
  showPrevious = true,
  showNext = true
}: StepControllerProps) {
  return (
    <div className="step-controller">
      {showPrevious && (
        <button className="next-field next-field-personal-data" onClick={onPreviousClick}>
          <img src={previousArrow} alt="next" /> Anterior
        </button>
      )}
      {showNext && (
        <button className="next-field next-field-personal-data" onClick={onNextClick}>
          Siguiente <img src={nextArrow} alt="next" />
        </button>
      )}
    </div>
  )
}
