import { UseFormRegisterReturn } from "react-hook-form"

interface Option {
  value: string
  label: string
}

interface RadioGroupProps {
  name: string
  label?: string
  options: Option[]
  error?: string
  disabled?: boolean
  register?: UseFormRegisterReturn
}

export default function RadioGroup({
  name,
  label,
  options,
  error,
  disabled = false,
  register
}: RadioGroupProps) {
  return (
    <div className="fam-groups">
      <label>{label}</label>
      <div className="radio-options">
        {options.map(option => (
          <label key={option.value} className="radio">
            <input
              type="radio"
              name={name}
              value={option.value}
              disabled={disabled}
              {...register}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
      {error ? <p className="instructions">{error}</p> : null}
    </div>
  )
}
